import React from "react";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import Heading from "components/Heading";
import PointerParent from "components/PointerParent";
import RawHTMLStyled from "components/RawHTMLStyled";
import ScrollToTop from "components/ScrollToTop";
import backgroundOverlay from "assets/images/header_skew/generic_header.jpg";

export default function AccessibilityStatementPage() {
  const title = "Accessibility Statement";
  const url = "accessibility-statement";

  const points = ["headingslim1", "headingslim2", "headingslim3"];

  return (
    <Main page={"fqa"} pagetitle={title}>
      <HeaderSkew
        title={title}
        url="faq"
        background={backgroundOverlay}
        appearance="small"
      />

      <PointerParent points={points}>
        <Block layer="1" name="FAQ" guides={1} appearance="regular">
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[0]}>
              General
            </Heading>
            <RawHTMLStyled>
              <>
                <p>
                  Loanpal, LLC cares about all of our customers and is committed
                  to facilitating and improving the accessibility and usability
                  of its Website, legacy.loanpal.com (the “Website”), ensuring
                  that our Website services and content are accessible to
                  persons with disabilities including, but not limited to users
                  of screen reader technology. To accomplish this, Loanpal, LLC
                  has partnered with UsableNet Inc (“UsableNet”), a leading web
                  accessibility consultant, by implementing its technology that
                  will enable and maintain accessibility in-line with the Web
                  Content Accessibility Guidelines (WCAG), which also brings the
                  Website into conformance with the Americans with Disabilities
                  Act of 1990.
                </p>
                <p>
                  The initial accessibility work has been completed (where
                  testing has been performed both with automatic accessibility
                  testing tools and users of screen readers to identify issues)
                  and remediation has been applied. UsableNet will continue to
                  monitor the Website and make accessibility updates when
                  needed. Users can “Enable Accessibility” via the link at the
                  top of the site navigation and always get the accessibility
                  enabled enhancements ensuring equal access to all features and
                  functions.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[1]}>
              Disclaimer
            </Heading>
            <RawHTMLStyled>
              <>
                <p>
                  Please be aware that our efforts to maintain accessibility and
                  usability are ongoing. While we strive to make the Website as
                  accessible as possible some issues may be encountered by
                  different assistive technology as the range of assistive
                  technology is wide and varied. We appreciate your
                  understanding.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[2]}>
              Contact us
            </Heading>
            <RawHTMLStyled>
              <>
                <p>
                  If, at any time, you have specific questions or concerns about
                  the accessibility of any particular web page on the Website,
                  then please contact us by e-mail at{" "}
                  <a
                    href="mailto:support@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@loanpal.com
                  </a>{" "}
                  or by phone at{" "}
                  <a
                    href="tel:1-844-loanpal"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    1-844-LOANPAL
                  </a>
                  . If you do encounter an accessibility issue, then please be
                  sure to specify the web page and nature of the issue, and we
                  will make all reasonable efforts to make that page or the
                  information contained therein accessible for you. Thanks for
                  visiting.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
        </Block>
        <ScrollToTop modifier={["skewed"]} />
      </PointerParent>
    </Main>
  );
}
